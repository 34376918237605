@import "../constants.scss";

.session-content {
  margin: 0 0 auto 0;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
}

@supports not (scrollbar-gutter: stable) {
  .session-content {
    padding-left: 18px;
  }
}

.no-active-session {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $MAIN_CONTENT_BACKGROUND_COLOR;
}

.no-active-session-text {
  color: darkgrey;
}

.new-session-message {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  padding: 10vh 20px 20px 20px;
}

.new-session-message-centered {
  justify-content: center;
  padding: 20px;
}

.new-session-solver-logo {
  width: 100px;
  height: 100px;
  background-color: #505050;
  mask-image: url("../images/solver_icon_reverse_transparent.png");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.welcome-message-container {
  font-size: 1.5em;
  color: #666666;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.welcome-message-container-small {
  font-size: 1.25em;
  justify-content: flex-end;
}

.welcome-message-title {
  font-size: 1.5em;
  font-weight: 600;
  margin: 0;
  line-height: 1.3;
  color: #e6e6e6;
  user-select: none;
  cursor: default;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}

.welcome-message-description {
  font-size: 1rem;
  margin: 0;
  line-height: 1.5;
  color: #8c8c8c;
  user-select: none;
  cursor: default;
  max-width: 700px;
}

.welcome-message-line {
  margin: 0;
  line-height: 1.4;
  user-select: none;
  cursor: default;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  width: 75%;
  max-width: 500px;
  align-self: center;
  margin-top: 20px;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.separator::before {
  margin-right: 1em;
}
.separator::after {
  margin-left: 1em;
}
.connect-repo-button {
  align-self: center;
  min-width: 160px;
}

.active-session-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 10px;
  border-bottom: 1px solid #424242;
}

.active-session-title-bar .ant-tooltip-wrapper {
  line-height: 1;
}

.active-session-title-container {
  color: #dedede;
  font-size: 18px;
  font-weight: 700;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 1000000;
}

.active-session-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.active-session-title-edit {
  max-width: 200px;
}

.session-title-edit-trigger {
  color: #909090;
  font-size: 14px;
}

.session-title-edit-trigger:hover {
  color: #dedede;
}

.active-session-controls-container {
  display: flex;
  gap: 10px;
}

.active-session-controls {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
  white-space: nowrap;
  flex-wrap: wrap;
}

.active-session-controls-divider {
  border-left: 1px solid #424242;
}

.active-session-controls .ant-radio-group {
  display: flex;
  flex-wrap: wrap;
}

.active-session-controls-radio {
  color: #b4b4b4;
}

.active-session-controls-button {
  box-shadow: none;
  background-color: transparent;
  color: #b4b4b4;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 6px;
}

.active-session-controls-button-primary {
  background-color: $SOLVER_BLUE;
  color: revert;
  border: none;
}

.active-session-controls-button-primary:hover {
  color: #fff !important;
  background-color: #5fa4cc !important;
}

.active-session-controls-button-primary:disabled:hover {
  color: rgba(255, 255, 255, 0.25) !important;
  background-color: rgba(255, 255, 255, 0.08) !important;
}

.active-session-controls-button > .ant-btn-icon {
  margin-inline-end: 0px !important;
}

// When wrapped in a tooltip, the button sits inside a span with this antd class.
.active-session-controls-button.ant-tooltip-disabled-compatible-wrapper {
  padding: 0;
}

.active-session-controls-button > button {
  @extend .active-session-controls-button;
}

.active-session-controls-button-small {
  padding: 8px !important;
  justify-content: center;
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .active-session-title-bar {
    justify-content: center;
  }

  .active-session-controls-button {
    font-size: 0.8em;
  }

  .active-session-controls-button > button {
    font-size: 0.9em;
  }

  .active-session-controls-button-small {
    width: unset !important;
  }

  .active-session-controls-button-small .anticon {
    font-size: 1em !important;
  }
}

.input-container {
  padding: 0 15px;
  padding-bottom: 10px;
}

.push-to-remote-modal-title,
.push-to-remote-modal-loading-icon {
  text-align: center;
}

.push-to-remote-modal-loading-icon {
  font-size: 2em;
}

.push-to-remote-modal-link {
  color: $SOLVER_BLUE;
}

.push-to-remote-modal-loading-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.copy-patch-modal-copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.copy-patch-modal-instructions {
  margin-top: 16px;
  border-top: 1px solid #424242;
  padding-top: 16px;
}

.copy-patch-modal-instructions-pre pre {
  background-color: #141414;
  padding: 12px;
  border-radius: 4px;
  margin: 8px 0;
}
