@import "../../constants.scss";

.terminal-wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.terminal {
  margin-top: 0;
  background-color: #141414;
  color: #dcdcdc;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  max-height: 240px;
  overflow-y: auto;
  flex: 1;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 12px;
}

.terminal-copy-button {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #dcdcdc;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1;

  &:hover {
    color: #ffffff;
    background: rgba(0, 0, 0, 0.8);
  }
}

.terminal-wrapper:hover {
  .terminal-copy-button {
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
}

.terminal-error {
  color: #dc3545;
}

.terminal-label {
  font-family: $CODE_FONT_FAMILY;
  font-size: 12px;
}
