.branch-status-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 2px;
  margin: 0px 0;
  color: rgba(255, 255, 255, 0.45);
  font-size: 12px;
  font-weight: normal;

  .anticon {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.45);
  }
}
