@import "../constants.scss";

.image-input {
  .image-pills {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
  }

  .image-pill {
    .ant-btn-primary {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      height: 24px;

      .thumbnail-container {
        width: 16px;
        height: 16px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .preview-thumbnail {
          width: 16px;
          height: 16px;
          border-radius: 2px;
          object-fit: cover;
          transition: opacity 0.2s ease;
        }

        .delete-overlay {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 0.75em;
          font-weight: bold;
          width: 100%;
          height: 100%;
          align-items: center;
          border: none;
          color: rgba(255, 255, 255, 0.75);
          justify-content: center;
          border-radius: 2px;
          cursor: pointer;
        }
      }

      &:hover {
        .preview-thumbnail {
          opacity: 0.4;
        }

        .delete-overlay {
          display: flex;
        }
      }
    }
  }

  :global {
    .ant-popover {
      .ant-popover-inner {
        padding: 0;
        overflow: hidden;

        .ant-popover-inner-content {
          padding: 0;
        }
      }

      .ant-popover-arrow {
        display: none; /* Hide the arrow for cleaner look */
      }

      /* Add some space between popover and pill */
      &.ant-popover-placement-top {
        padding-bottom: 8px;
      }
    }
  }

  .image-preview-popover {
    img {
      display: block;
    }
  }

  .simple-upload {
    padding: 4px;

    .upload-icon {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7);
      cursor: pointer;

      &:hover {
        color: $SOLVER_BLUE;
      }
    }

    &.ant-upload-disabled {
      .upload-icon {
        color: rgba(255, 255, 255, 0.25);
        cursor: not-allowed;

        &:hover {
          color: rgba(255, 255, 255, 0.25);
        }
      }
    }
  }

  :global {
    .ant-upload {
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: auto !important;
      height: auto !important;
    }

    // Style for preview modal
    .ant-modal-content {
      img {
        max-width: 100%;
        max-height: 80vh;
        object-fit: contain;
      }
    }
  }
}
