.glowing-tooltip-content {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .glowing-tooltip-got-it {
    align-self: flex-end;
    background-color: transparent;
    border: 1px solid #bababa;
    color: #bababa;
  }
}

.ant-tooltip-inner:has(.glowing-tooltip-content) {
  padding: 16px;
}
