@import "../constants.scss";

.sider-drawer {
  padding: 0;
}

.sider-container {
  position: relative;
  height: calc(100svh - $APP_HEADER_HEIGHT);
  background-color: $PERIPHERAL_CONTENT_BACKGROUND_COLOR;
  border-right: 1px solid #424242;
}

$SIDER_HEADER_HEIGHT: 46px;

.sider-view-mode-tabs {
  height: $SIDER_HEADER_HEIGHT;
  border-bottom: 1px solid #424242;
  padding-left: 15px;
  color: #979797;
  font-weight: 600;
}

.sider-view-mode-tabs .ant-tabs-nav {
  margin-bottom: 0;
}

.sider-view-mode-tabs .ant-tabs-nav:before {
  border-bottom: none;
}

.sider-view-mode-tabs-badge {
  position: absolute;
  left: 55px;
  font-size: 0.6rem;
  color: #979797;
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .sider-container {
    border-right: none;
  }
}

.sider {
  height: calc(100svh - $APP_HEADER_HEIGHT - $SIDER_HEADER_HEIGHT);
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: $PERIPHERAL_CONTENT_BACKGROUND_COLOR !important;
}

.sider-new-button-container {
  position: absolute;
  top: 56px;
  right: 15px;
  z-index: 1000;
  transition: all 0.2s;
}

.sider-new-button-container.collapsed {
  right: 4px;
  top: 4px;
}

.sider-collapsed {
  padding: 0;
}
