@import "../../constants.scss";

.image-attachment-container {
  overflow: visible;
}

.image-attachment-header {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  padding: 2px 0;
  user-select: none;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }

  .image-attachment-collapse-icon {
    color: rgba(255, 255, 255, 0.45);
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .ant-typography {
    cursor: pointer;
  }
}

.image-attachment-content {
  display: none;

  &.expanded {
    display: block;
    margin-top: 8px;
    padding: 12px;
    border-radius: 4px;
  }
}

.image-attachment-thumbnail {
  cursor: pointer;
  max-width: 300px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.image-attachment-error {
  color: #ff4d4f;
  margin-top: 4px;
  font-size: 12px;
}

.image-attachment-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  min-width: 200px;
  color: #e6e6e6;
}

.image-attachment-modal {
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }

  .ant-modal-close {
    color: white;
    top: -30px;
    right: -30px;
  }
}

.image-attachment-full {
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
  border-radius: 4px;
  display: block;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
