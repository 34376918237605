.planning-view-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.planning-view-problem {
  display: flex;
  align-items: center;
  gap: 4px;
}
