@supports (scrollbar-gutter: stable) {
  .scrollbar {
    overflow: hidden !important;
  }
}

.scrollbar:hover {
  overflow: auto !important;
}

// Safari supports scrollbar-gutter, but it doesn't work well with the behavior
// we want. We want to only show the scrollbar when hovering, but Safari doesn't does
// weird layout shifts when hovering. So we always show the scrollbar.
.scrollbar-safari {
  overflow-y: scroll !important;
  overflow-x: auto !important;
}

.scrollbar-safari:hover {
  overflow-y: scroll !important;
  overflow-x: auto !important;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar:horizontal {
  height: 8px;
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
  border-radius: 5px;
}

.scrollbar::-webkit-scrollbar-thumb:horizontal {
  background-color: #6b6b6b;
  border-radius: 5px;
}

.scrollbar-gutter-stable {
  scrollbar-gutter: stable;
}

.scrollbar-gutter-stable-both {
  scrollbar-gutter: stable both-edges;
}
