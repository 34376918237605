@import "../constants.scss";

.environment-configuration-required {
  display: flex;
  align-items: center;
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .environment-configuration-required {
    flex-direction: column;
  }
}

.environment-configuration-required-text {
  color: $SOLVER_BLUE;
  text-align: center;
  padding: 6px;
}

.environment-configuration-required-text:hover {
  cursor: pointer;
  color: #5fa4cc !important;
}
