@import "../constants.scss";

.safari-compatible-recorder {
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
  height: 40px; // Taller for better visibility and interaction

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .record-button-container {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 12px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 6px;
    transition: all 0.3s ease;
    height: 100%;
    width: 100%;

    &.recording {
      background: rgba(24, 144, 255, 0.08);
      min-width: min(800px, 100%); // Increased width for better visualization
      max-width: 100%; // Ensure it doesn't overflow container
    }
  }

  @media (max-width: $SMALL_SCREEN_WIDTH) {
    width: 100%;
    margin-right: 0;

    .record-button-container {
      justify-content: space-between;
      width: 100%;
    }
  }

  .record-button {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;

    &:hover {
      color: #40a9ff;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.start-save {
      font-size: 18px;

      &:not(.recording) {
        color: rgba(255, 255, 255, 0.85);
        font-size: 20px;
      }
    }

    &.discard {
      color: #ff4d4f;
      font-size: 14px;
    }
  }

  .audio-visualizer {
    flex: 1;
    height: 32px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 4px;
    margin: 0 4px;
    min-width: 0; // Prevent flex item from overflowing

    @media (max-width: $SMALL_SCREEN_WIDTH) {
      flex: 1;
      width: 100%;
    }
  }
}
