@import "../constants.scss";

.settings-container {
  width: 450px;
  height: fit-content;
  max-height: 490px;
  padding: 0 15px;
}

@media (max-width: 450px) {
  .settings-container {
    width: unset;
  }
}

.settings-description {
  color: #8c8c8c;
  font-size: 14px;
  margin-bottom: 16px;
}

.settings-instructions {
  font-size: 12px;
  color: grey;
  margin-top: 5px;
}

.settings-divider {
  margin: 10px 0;
}

.settings-title {
  /* override antd */
  margin: 0 !important;
}

.settings-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-current-user-info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.settings-current-user .ant-select {
  width: 180px;
}

.settings-button-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
}

.subscription-manage-container {
  display: flex;
  justify-content: left;
  padding-top: 16px;
  margin-top: 8px;
  border-top: 1px solid #424242;
}

.subscription-stats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  padding-top: 16px;
}

.subscription-stat-item {
  flex: 1;
  margin-bottom: 8px;

  @media (max-width: 450px) {
    flex: 1 0 100%;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    .ant-statistic-title,
    .ant-statistic-content {
      color: rgba(255, 255, 255, 0.45);
    }
  }
}

.subscription-action-button {
  width: auto;
  background: none;
  border: 1px solid #424242;
  color: #b4b4b4;
  font-weight: normal;
}

.subscription-dropdown-button {
  width: auto;

  .ant-btn {
    background: none;
    border: 1px solid #424242;
    color: #b4b4b4;
    font-weight: normal;
  }

  // Remove the divider line and handle borders
  .ant-btn:first-child {
    border-right: none;
  }

  .ant-btn-icon-only {
    border-left: 1px solid #424242;
  }

  // Override the Ant Design divider
  .ant-btn.ant-btn-icon-only.ant-dropdown-trigger {
    border-left-color: #424242 !important;
    &::before {
      display: none !important; // Remove the blue divider line
    }
  }
}

.execution-image-select {
  width: 100%;
}

.execution-image-select-label {
  color: grey;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.execution-custom-image-back-button {
  margin-bottom: 10px;
}

.execution-image-reset-button {
  margin-top: 10px;
}

.execution-image-custom-edit-button {
  border: none;
  background-color: transparent;
}

.execution-image-form-item {
  margin-bottom: 12px;
}

.execution-settings-loading {
  display: flex;
  align-items: center;
  gap: 4px;
}

.execution-env-vars-table-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.execution-env-vars-table-actions {
  display: flex;
  gap: 4px;
}

.execution-env-vars-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.execution-env-vars-table tr:first-child {
  border-top-left-radius: 5px;
}

.execution-env-vars-table th,
.execution-env-vars-table td {
  width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #393939;
  padding: 4px;
}

.execution-env-vars-table th {
  background-color: #2d2c2c;
}

.execution-env-vars-table td {
  font-family: $CODE_FONT_FAMILY;
  font-size: 12px;
  background-color: #262626;
}

.execution-env-vars-value-container {
  display: flex;
  justify-content: space-between;
}

.execution-env-vars-value-container:hover .execution-env-vars-value-delete {
  display: block;
}

.execution-env-vars-value-delete {
  display: none;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: grey;
}

.execution-env-vars-value-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.execution-env-vars-value-delete:hover {
  background-color: #363535;
}

.execution-env-vars-input {
  font-family: $CODE_FONT_FAMILY;
  font-size: 12px;
  border: none;
  background-color: transparent;
  padding-left: 0;
}

.execution-env-vars-input:focus {
  border: none;
  box-shadow: none;
}

.execution-env-vars-input * {
  font-family: $CODE_FONT_FAMILY;
  font-size: 12px;
  border: none !important;
  background-color: transparent !important;
  padding-left: 0;
  /* box-shadow: none !important; */
}

.execution-env-vars-input *:focus {
  border: none !important;
  box-shadow: none !important;
}

.execution-env-vars-input .ant-input-group-addon {
  padding: 0;
}

.execution-env-vars-input .ant-input-group-addon:focus {
  box-shadow: none;
  border: none;
}

.execution-env-var-secret-value-checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.execution-env-var-secret-value-lock-icon {
  font-size: 0.75em;
  box-shadow: none;
}

.execution-env-vars-button {
  width: 30%;
}

.execution-env-vars-add-button {
  align-self: flex-end;
}
