.inline-message {
  display: flex;
  align-items: center;
  margin-left: 16px;
  color: #999;
  gap: 8px;
  background-color: transparent;
  justify-content: space-between;
}

.agent-message:has(.inline-message),
.user-message:has(.inline-message) {
  background-color: transparent;
  padding-top: 0px;
  padding-bottom: 0px;
}

.inline-message-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.inline-message-container {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.inline-message-icon {
  font-size: 18px;
}

.inline-message-text {
  font-size: 14px;
}

.inline-message-actions {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}
