@import "../constants.scss";

$HIGHLIGHT_BACKGROUND_COLOR: #26292c;

.diff-code-selected {
  opacity: 0.5;
}

.diff-gutter-selected {
  opacity: 0.5;
}

.diff-gutter {
  cursor: default;
  font-family: $CODE_FONT_FAMILY;
}

.diff-gutter > a {
  cursor: default;
}

.diff-gutter-edit-link:hover {
  color: #476ab4;
  cursor: pointer;
}

.diff-gutter-commentable {
  position: relative;
}

.diff-gutter-comment-button {
  position: absolute;
  z-index: 2;
  right: -20px;
  background-color: $SOLVER_BLUE;
  border-radius: 6px;
  border: 1px solid #478bb3;
  padding: 2px 4px;
  font-size: 10px;
  cursor: pointer;
}

.diff-gutter-comment-button:hover {
  transition: all 0.2s ease;
  border: 1px solid $SOLVER_BLUE_HOVER;
}

.diff-code {
  font-family: $CODE_FONT_FAMILY;
}

.diff-code-insert {
  &::before {
    content: "+";
    letter-spacing: 10px;
  }
}

.diff-code-delete {
  &::before {
    content: "-";
    letter-spacing: 10px;
  }
}

.diff-code-normal {
  &::before {
    content: " ";
    letter-spacing: 10px;
  }
}

.diff-code-header {
  &::before {
    content: " ";
    letter-spacing: 10px;
    white-space: pre;
  }
}

.diff-code-normal {
  background-color: #12171d;
  font-size: $DIFF_TEXT_FONT_SIZE;
}

.diff-gutter-normal {
  background-color: #12171d;
  font-size: $DIFF_TEXT_FONT_SIZE;
  color: grey;
}

.diff-code-insert {
  background-color: #1f2e25;
  font-size: $DIFF_TEXT_FONT_SIZE;
}

.diff-gutter-insert {
  background-color: #324d2f;
  font-size: $DIFF_TEXT_FONT_SIZE;
}

.diff-code-delete {
  background-color: #2b1e22;
  font-size: $DIFF_TEXT_FONT_SIZE;
}

.diff-gutter-delete {
  background-color: #542b2b;
  font-size: $DIFF_TEXT_FONT_SIZE;
}

.diff-code-header {
  background-color: #1c2537;
  font-size: $DIFF_TEXT_FONT_SIZE;
  font-family: $CODE_FONT_FAMILY;
  color: #a2a2a2;
  vertical-align: middle !important;
  /* Override react-diff-view. */
}

.diff-gutter-header {
  background-color: #31487b;
  font-size: $DIFF_TEXT_FONT_SIZE;
  color: #a2a2a2;
}

.diff-code-footer {
  background-color: #1c2537;
  font-size: $DIFF_TEXT_FONT_SIZE;
  color: #a2a2a2;
}

.diff-gutter-footer {
  background-color: #31487b;
  font-size: $DIFF_TEXT_FONT_SIZE;
  color: #a2a2a2;
}

/* Match the background color of inline edits to the gutter. */
.diff-code-delete .diff-code-edit {
  background-color: #542b2b;
  font-size: $DIFF_TEXT_FONT_SIZE;
}

.diff-code-insert .diff-code-edit {
  background-color: #324d2f;
  font-size: $DIFF_TEXT_FONT_SIZE;
}

.diff-code-omit {
  background-color: #191f28;
}

.diff-gutter-omit {
  background-color: #12171d;
}

.diff-gutter-omit:before {
  background-color: #12171d;
}

.diff-expander {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  cursor: pointer;
}

.diff-expander:hover {
  background-color: #476ab4;
}

.above-highlight-code {
  background-color: $HIGHLIGHT_BACKGROUND_COLOR;
}

.above-highlight-gutter {
  background-color: $HIGHLIGHT_BACKGROUND_COLOR;
}

.above-highlight-button {
  float: right;
  border-radius: 0px;
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}

.below-highlight-code {
  background-color: $HIGHLIGHT_BACKGROUND_COLOR;
  height: 2px;
}

.below-highlight-gutter {
  background-color: $HIGHLIGHT_BACKGROUND_COLOR;
  height: 2px;
}

.diff-gutter-normal:has(.diff-gutter-highlight) {
  background-color: #362f1d;
}

.diff-gutter-delete:has(.diff-gutter-highlight) {
  background-color: #4d3618;
}

.diff-gutter-insert:has(.diff-gutter-highlight) {
  background-color: #375229;
}

.diff-gutter:has(.diff-gutter-highlight-new) {
  border-right: 2px solid #aa8f4c;
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .diff-gutter-col {
    width: 4ch;
  }
}

.code-comment-container {
  padding: 10px;
  background-color: #141414;
  border: 1px solid #424242;
  border-radius: 6px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: default;
}

.code-comment-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.diff-too-large {
  background-color: #242424;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #979797;
}
