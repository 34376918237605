@import "../constants.scss";

.project-execution-header {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.task-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 800px;
}

.task-card {
  background-color: $MAIN_CONTENT_BACKGROUND_COLOR;
  margin-bottom: 0;
  color: #b8b8b8;

  .ant-card-body {
    padding: 10px;
  }

  &.completed {
    .ant-card-body {
      border-left: 4px solid #257000;
    }
  }

  &.has-session {
    .ant-card-body {
      border-left: 4px solid #1890ff;
    }
  }

  &.no-session {
    .ant-card-body {
      border-left: 4px solid #d9d9d9;
    }
  }

  .task-card-summary {
    margin: 0;
  }

  .task-status {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .task-status-tag {
      white-space: normal;
    }

    .linkable-tag {
      cursor: pointer;
    }

    .linkable-tag:hover {
      text-decoration: underline;
    }

    .span.task-status-tag-text::first-letter {
      text-transform: uppercase;
    }
  }

  .task-card-divider {
    margin: 0.5rem 0;
    border-bottom: 1px solid #303030;
  }
}
