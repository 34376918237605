@import "../constants.scss";

.new-project-button {
  &[disabled] {
    pointer-events: none;
  }
}

.ant-modal {
  .ant-modal-content {
    background-color: $PERIPHERAL_CONTENT_BACKGROUND_COLOR;
  }

  .ant-modal-header {
    background-color: $PERIPHERAL_CONTENT_BACKGROUND_COLOR;

    .ant-modal-title {
      color: #fff;
    }
  }

  .ant-form {
    margin-top: 16px;

    .ant-form-item-label > label {
      color: rgba(255, 255, 255, 0.85);
    }

    .ant-input {
      background-color: $INPUT_BACKGROUND_COLOR;
      border-color: transparent;
      color: #fff;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: $INPUT_BACKGROUND_COLOR;
      border-color: transparent;
      color: #fff;
    }
  }

  .ant-form-item:last-child {
    margin-bottom: 0;
  }

  .ant-modal-close {
    color: rgba(255, 255, 255, 0.45);

    &:hover {
      color: rgba(255, 255, 255, 0.75);
    }
  }

  .ant-modal-footer {
    .ant-btn-default {
      background-color: transparent;
      border-color: #424242;
      color: #fff;

      &:hover {
        border-color: #40a9ff;
        color: #40a9ff;
      }
    }
  }
}
