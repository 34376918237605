@import "../constants.scss";

.branch-selector-loading {
  padding: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.branch-selector-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .branch-selector-wrapper {
    max-width: 250px;
  }
}

.branch-selector-instructions {
  font-size: 12px;
  color: grey;
}

.branch-selector-select {
  width: 100%;

  .ant-select-selector {
    background-color: $INPUT_BACKGROUND_COLOR !important;
    border-color: transparent !important;
    color: #fff !important;
  }

  .ant-select-selection-placeholder {
    color: rgba(255, 255, 255, 0.45) !important;
  }

  .ant-select-arrow {
    color: rgba(255, 255, 255, 0.45) !important;
  }

  &.ant-select-focused .ant-select-selector {
    box-shadow: 0 0 0 2px rgba(64, 154, 212, 0.2) !important;
  }
}

// Dropdown styles
.ant-select-dropdown {
  background-color: $PERIPHERAL_CONTENT_BACKGROUND_COLOR !important;

  .ant-select-item {
    color: #fff !important;
    padding: 8px 12px !important;

    &:hover {
      background-color: rgba(255, 255, 255, 0.08) !important;
    }

    &.ant-select-item-option-selected {
      background-color: rgba(64, 154, 212, 0.15) !important;
    }
  }

  .ant-select-empty {
    color: rgba(255, 255, 255, 0.45) !important;
  }
}

.branch-selector-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.branch-selector-default-badge {
  border: 0.5px solid grey;
  color: grey;
  border-radius: 4px;
  padding: 0 4px;
  font-size: 11px;
  flex-shrink: 0;
  line-height: 1.4;
  display: inline-block;
}
