@import "../../constants.scss";

.tech-plan-error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.tech-plan-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--peripheral-content-background-color);
}

.tech-plan-loading {
  opacity: 0.7;
  pointer-events: none;
}

.tech-plan-task {
  border: 1px solid transparent;
  padding: 5px;
  border-radius: 4px;
  background-color: #2c2c2c;
}

.tech-plan-task-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #424242;
  transition: background-color 0.3s ease;
}

.tech-plan-task-header:hover {
  background-color: var(--main-content-background-color);
}

.tech-plan-task-body {
  padding: 5px;
}

.tech-plan-task-section {
  margin-bottom: 24px;
  // background-color: #181818;
}

.tech-plan-task-section:last-child {
  margin-bottom: 0;
}

.tech-plan-task-section-title {
  font-weight: 600;
  margin-bottom: 12px;
}

.tech-plan-question {
  border-radius: 4px;
  margin-bottom: 16px;
}

.tech-plan-question:last-child {
  margin-bottom: 0;
}

.tech-plan-question-text {
  font-weight: 500;
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
}

.tech-plan-answer-input {
  margin-bottom: 16px;
  background-color: transparent;
  transition: all 0.3s ease;
  border: 1px solid #808080;
  margin: 0 10px;
  max-width: 90% !important;
}

.tech-plan-answer-input:hover {
  border-color: $SOLVER_BLUE_HOVER;
}

.tech-plan-answer-input.focused {
  border-color: var(--solver-blue);
  box-shadow: 0 0 0 2px rgba(64, 154, 212, 0.2);
}

.tech-plan-answer-input.answered {
  border-color: #52c41a;
  background-color: rgba(82, 196, 26, 0.05);
}

.tech-plan-answer-input.answered:hover {
  border-color: #52c41a;
  box-shadow: 0 0 0 2px rgba(82, 196, 26, 0.1);
}

.tech-plan-answer-input.answered:not(.focused) {
  resize: none;
}

.tech-plan-dependencies {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tech-plan-dependency {
  cursor: pointer;
  padding: 4px;
  white-space: normal;
}

.tech-plan-dependency:hover {
  text-decoration: underline;
}

/* Animation for collapse/expand */
.tech-plan-task-body {
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.tech-plan-task-collapsed .tech-plan-task-body {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
  margin: 0;
}

.tech-plan-tasks-empty {
  text-align: center;
  padding: 40px;
  background-color: var(--peripheral-content-background-color);
  border-radius: 8px;
  border: 1px dashed var(--main-content-background-color);
}

.tech-plan-tasks-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.tech-plan-tasks-header .ant-btn {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tech-plan-task:hover::before {
  opacity: 1;
}

/* Question styling */
.tech-plan-question {
  position: relative;
  border: 1px solid var(--peripheral-content-background-color);
  transition: border-color 0.3s ease;
}

.tech-plan-question:hover {
  border-color: var(--solver-blue-hover);
}

/* Styling for project-level questions */
.tech-plan-project-questions {
  border-radius: 8px;
  border: 1px solid var(--main-content-background-color);
  margin-bottom: 24px;
}

.tech-plan-project-questions-title {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 16px;
}

.tech-plan-potential-answers {
  margin: 0 10px 10px 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tech-plan-potential-answer-button {
  background-color: transparent;
  border: 1px solid #b5b5b5;
  border-radius: 20px;
  box-shadow: none;
  font-size: 14px;
  color: #b5b5b5;
  white-space: normal;
  word-wrap: break-word;
  height: auto;
}
