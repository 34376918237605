@import "../constants.scss";

.markdown > *:first-child {
  margin-top: 0;
}

.markdown > *:last-child {
  margin-bottom: 0;
}

.markdown > * {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.markdown-h1 {
  /* Webkit's user agent stylesheet sets h1s under article layout elements
  (article, aside, etc...) to be smaller than default h1s. This resets that. */
  font-size: 2em;
}

.markdown-paragraph {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.markdown-pre {
  background-color: #1f1f1f;
  border-radius: 5px;
  padding: 15px;
  overflow: auto;
  font-family: $CODE_FONT_FAMILY;
}

.markdown-code {
  background-color: #1f1f1f;
  border-radius: 5px;
  padding: 2px;
  overflow-wrap: anywhere;
  font-family: $CODE_FONT_FAMILY;
}

.markdown-pre > code {
  padding: 0;
  white-space: pre-wrap !important;
  word-break: break-all !important;
}

.markdown-img {
  max-width: 100%;
}

.markdown-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.markdown-th,
.markdown-td {
  padding: 10px;
  text-align: left;
  border: 1px solid #a7a7a7;
}

.markdown-a {
  color: inherit;
  text-decoration: underline;
}

.markdown-a:hover {
  color: #9e9e9e;
  text-decoration: underline;
}

.markdown-pre:has(.markdown-diff-card) {
  padding: 0;
}

.markdown-diff {
  margin-bottom: 10px;
}

.markdown-diff:last-child {
  margin-bottom: 0;
}
